<template>
  <v-treeview
    :active="tree"
    @update:active="(v) => tree = v[0]"
    :items="folders"
    activatable
    item-key="path"
    :multiple-active="false"
    return-object
    dense
  >
    <template v-slot:prepend="{ item, open }">
      <v-icon left v-if="!item.is_file">
        {{ open ? "mdi-folder-open" : "mdi-folder" }}
      </v-icon>
      <v-icon left v-else>
        {{ icons[item.extension] }}
      </v-icon>
    </template>

    <template v-slot:append="{ item, active }">
      <div class="d-flex items-center" v-if="active && (sub != 'final' || $root.acceso('EDITAR_ESTUDIOS'))">
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="$emit('deleteFolder', item)">
          <v-icon small>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </template>
  </v-treeview>
</template>

<script>
export default {
  props: {
    folders: Array,
    currentFolder: Object | Array,
    sub: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      icons: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf-box",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xlsx: "mdi-file-excel",
        zip: "mdi-folder-zip-outline",
      },
    };
  },
  computed: {
    tree: {
      get() { return [this.currentFolder] },
      set(v) { this.$emit('changeFolder', v) }
    },
    justFolders() {
      return this.folders.filter(function f(o) {
        if (o.children) {
          return (o.children = o.children.filter(f));
        }
        return o.children;
      });
    },
  },
};
</script>
